import * as React from "react";
import { useEffect } from "react";

export default function LegalsHome({ navigate }) {

  useEffect(() => {
    navigate("/legals/terms");
  }, [navigate]);

  return (
    <section className="not-found">
    </section>
  );
};
